.weather-container {
  margin: 10px;

  &__long-bottom-margin {
    margin-bottom: 32px;
  }

  &_margin-bottom {
    margin-bottom: 16px;
  }

  &__lastAvg {
    font-size: 1.3rem;
  }

  &_graph {
    & .recharts-tooltip-item-list li:nth-of-type(n + 4) {
      display: none !important;
    }
  }

  &__type {
    cursor: pointer;
    color: #9b9b9b;

    &-wrap {
      margin: 12px 0;
    }

    &-separator {
      margin: 0 5px;
      color: #ccc;
    }

    &_active {
      cursor: default;
      color: #069c61;
    }

  }

  &_flex {
    display: flex;
    align-items: center;
  }

  .react-datepicker-wrapper {
    width: 110px;
  }
}

.weather {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;

  &-checkbox {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }

  &-link {
    color: #069c61;
    text-decoration: underline;

    &:hover {
      color: #069c61;
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  &--hourly {
    justify-content: flex-start;
  }

  &--header-3-hours {
    display: flex;
    align-items: center;
  }

  &--hourly-toggle-arrow {
    width: 18px;
    margin: 0 8px;
    cursor: pointer;
    filter: grayscale(100%);

    &-disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &.left {
      transform: rotate(90deg);
    }

    &.right {
      transform: rotate(270deg);
    }
  }
}

.weather-history {
  margin: 0 10px;
}

.weather__wind_capitalize::first-letter {
  text-transform: capitalize;
}

.weather-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  &__icon {
    width: 15px;
    margin-right: 5px;

    &_wind {
      width: auto;
      height: 17px;

      &-small {
        height: 16px;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
    width: 100px;

    &_large-size {
      width: 117px;
    }

    &--header {
      text-decoration: underline;
    }
  }

  &:after {
    content: '';
    position: absolute;
    padding-right: 7px;
    margin-right: -7px;
    height: 100%;
    right: 7px;
    border-right: 1px solid $color-white;
  }

  &--last:after {
    content: none;
  }

  &--inactive {
    cursor: pointer;
    background: #eee;

    .weather-item__row--header {
      text-decoration: none;
    }
  }

  &--hourly {
    // evenly devide hours components by 3 hours
    width: percentage(1 / (24 / 3));
    border-right: 1px solid $color-gray-light;
  }
}

.weather-current {
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    font-size: 17px;
    align-items: center;
    margin-right: 15px;

    * {
      margin: 0 5px 0 0;
    }
  }

  &__icon {
    width: 30px;
    margin-right: 5px;
  }
}

.weather-source-selection {
  margin: 12px 0;
  width: 400px;
}

.weather-current-station {
  color: $color-gray-text;

  &__header {
    margin-bottom: 12px;
  }

  &__info {
    color: #e8b227;
    margin-top: 4px;
  }

  &__block {
    margin-bottom: 12px;
  }
}

.weather-spray-wrapper {
  width: 100%;
}

.weather-daily-wrapper {
  width: 100%;
  display: flex;
}

.night-day-container {
  white-space: nowrap;
}

.night {
  color: #5190cc;
}

.day {
  color: #e5ac27;
}

.weather-station {
  color: $color-black;
  font-size: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: $color-white;
  border-radius: 50% !important;
  opacity: 0.8;

  &__link {
    font-size: 12px;
    margin-top: 8px;
  }
}

.history-weather {
  &__subtitle {
    margin-bottom: 8px;
  }

  &__title {
    margin: 24px 0 8px;

    &_min-bottom {
      margin-bottom: 4px;
    }
  }
}

.aggregates-weather {
  &__divider {
    line-height: 33px;
    margin: 0 2px;
  }

  &__error {
    padding-top: 12px;
    color: #600;
    font-weight: bold;
  }

  &__button-spinner {
    margin: 0 8px 0 0;
    width: 20px;
    height: 20px;

    & .loader-child::before {
      background-color: #fff
    }
  }

  &__button {
    border: 1px solid #ccc;
    margin-left: 16px;
    height: 34px;
    margin-top: 25px;
    align-items: center;

    &_no-border {
      border: 0;
      display: flex;
    }
  }

  &__label {
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__filter {
    &:not(:first-of-type) {
      margin-left: 16px;
    }
  }
}
