.list-group-fields-item {
  padding: 0 10px;
  border-bottom: 1px solid $color-gray-light;
  cursor: pointer;
  display: flex;
  height: 66px;

  &__text {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: inherit;
  }

  &:hover,
  &:focus,
  &.active {
    background: $color-white;
  }

  p {
    margin: 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    overflow: hidden;
  }

  &__info {
    width: 95%;
  }
}

.create-field-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;

  & button {
    flex: 1;
    height: $panel-height;
  }
}

.add-field {
  text-align: center;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 0;
  height: calc(#{$panel-height} - 2px);

  &_column {
    flex-direction: column-reverse;
    height: 2 * $panel-height;

    &-third {
      height: 3 * $panel-height;
    }

    & .add-field-input {
      border-radius: 0;
    }

    & .add-field__button {
      width: 50%;
      border-radius: 0;

      &_full-width {
        width: 100%;
      }

      &-text {
        font-weight: 500;
        margin-left: 4px;
        font-size: 12px;
        margin-top: -2px;
      }

      &_inc-size .add-field__button-text {
        font-size: 15px;
      }
    }

    & .add-field__buttons {
      border-top: 1px solid #ececec;
      height: $panel-height;
    }
  }

  &_column-small {
    flex-direction: column-reverse;
    height: $panel-height;
  }

  &__select {
    width: 100%;
    padding: 0 5px;
    text-align: left;
  }

  &__button {
    width: 56px;
    border: none;
    border-right: 1px solid $color-gray-light;
    background: white;
    height: 100%;
  }

  &__buttons-mock-panel {
    background: white;
    height: $panel-height;
    margin-left: -1px;
  }
}

.add-field-input {
  flex: 1;

  &_gps {
    min-height: $panel-height;
  }

  &__input-wrap {
    border: none !important;
    box-shadow: none;
    height: 100%;
  }
}

.field-list-tabs {
  &__wrapper {
    display: flex;
  }

  &__item {
    flex: 1;
    padding: 6px;
    border-bottom: 2px solid transparent;
    outline: none;
    color: #555;
    font-size: 14px;
    text-align: center;

    &.active {
      border-bottom: 2px solid #069c61;
      // pointer-events: none;
      cursor: default;
    }
  }
}

.fields-panel {
  &__info {
    height: 80px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    background: white;
    padding: 0 12px;

    &_item-gps {
      display: flex;
      justify-content: space-between;
      & div {
        width: 127px;
      }
    }

    &_item {
      display: flex;
      justify-content: space-between;
      & div {
        width: 93px;
      }
    }
  }

  &__gps-tech-form {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }

  &__gps-tech-settings {
    flex: 1;
    padding: 0 12px 0 4px;
    height: 80px;
    display: flex;
    flex-direction: column;

    & .breadcrumb {
      margin-bottom: 0;
    }
  }

  &__gps-tech-types {
    width: 268px;
    background: white;
    padding: 0 12px 0 4px;
    height: 60px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4px 24px;
    justify-content: center;
    overflow-x: auto;

    @media (max-width: 1530px) {
      gap: 4px 8px;
    }

    & .field-map-info__item_center {
      margin-right: 8px;
    }
  }

  &__cultures {
    flex: 1;
    background: white;
    padding: 0 12px 0 4px;
    height: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4px 24px;
    justify-content: center;
    overflow-x: auto;

    @media (max-width: 1530px) {
      gap: 4px 8px;
    }

    & .field-map-info__item_center {
      margin-right: 8px;
    }
  }

  &__settings {
    height: 80px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    background: white;
    padding: 0 12px 0 9px;
  }
}

.vegetation-table {
  & td {
    vertical-align: text-top;
  }
}

.vegetation-graph {
  & line[x1='NaN'] {
    display: none;
  }
}
